<div
  style="
    background-color: white;
    max-width: max-content;
    width: 100%;
    max-height: max-content;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  "
>
  <div style="width: 100%; height: 100%; padding: 48px">
    <h2>Välj plan</h2>
    <p class="bolderCardText">Nästan klart - välj plan och skapa ditt konto.</p>
    <ng-container *ngIf="route.data | async as data">
      <p
        class="cardText bolderCardText"
        *ngIf="data['userDto'] && data['userDto'].ownedCompanies[0].email"
      >
        {{ data["userDto"].ownedCompanies[0].email }}
      </p>
      <mat-radio-group
        [(ngModel)]="selectedPlan"
        style="
          display: flex;
          flex-direction: column;
          margin-top: 32px;
          align-items: center;
          width: 100%;
        "
      >
        @for (product of data['productsDto']; track product) {
        <div
          (click)="onClickCard(product.priceId)"
          matRipple
          matRippleColor="#43a87b22"
          [ngClass]="{
            paymentPlanCard: true,
            paymentPlanCardSelected: selectedPlan === product.priceId
          }"
        >
          <div style="display: flex; flex-direction: column">
            <p class="cardText bolderCardText">
              {{ product.name }}
            </p>
            <ng-container *ngIf="product.couponDiscountedPriceSEK">
              <div class="discountPriceInfo">
                <h3 style="margin-right: 8px">
                  {{
                    displayCorrectPrice(product.couponDiscountedPriceSEK) + "*"
                  }}
                </h3>
                <p class="strikedPriceText">
                  {{ displayCorrectPrice(product.priceSEK) }}
                </p>
              </div>
            </ng-container>
            <ng-container *ngIf="!product.couponDiscountedPriceSEK">
              <h3>
                {{ displayCorrectPrice(product.priceSEK) + "*" }}
              </h3>
            </ng-container>
            <mat-chip-set *ngIf="product.priceSEK > 700">
              <mat-chip
                [disabled]="false"
                class="chipStyle"
                style="background-color: #c5e7d9"
              >
                <p
                  style="
                    font-family: Work Sans;
                    font-weight: 500;
                    font-size: 16px;
                    color: #54b88e;
                  "
                >
                  Rekommenderad
                </p>
              </mat-chip>
            </mat-chip-set>
            <p class="cardText">{{ product.description }}</p>
          </div>
          <mat-radio-button
            id="annually"
            color="primary"
            [value]="product.priceId"
          ></mat-radio-button>
        </div>
        }
      </mat-radio-group>
      <p class="bolderCardText italicText">*Alla priser är exklusive moms.</p>
      <button
        mat-flat-button
        color="primary"
        style="
          width: 100%;
          border-radius: 100px;
          font-size: 16px;
          margin-bottom: 16px;
          margin-top: 32px;
        "
        (click)="proceedToPayment()"
        [disabled]="selectedPlan === '' || (showSpinner$ | async)"
      >
        Välj plan och betala
      </button>
    </ng-container>
    <button
      mat-button
      style="width: 100%; border-radius: 100px; font-size: 16px"
      (click)="logOut()"
    >
      Gör senare och logga ut
    </button>
  </div>
  <mat-progress-bar
    *ngIf="showSpinner$ | async"
    mode="indeterminate"
  ></mat-progress-bar>
</div>
